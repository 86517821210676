
@import "~bootstrap-material-design/scss/core";
@import "../../node_modules/datatables.net-bs4/css/dataTables.bootstrap4";

// align icons to text
i.material-icons {
  font-size: 16px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

// set form labels as required
label.required:after {
  content: " *";
}